import React from 'react'
import ExternalCard from '../../utils/ExternalCard'

function Indicators() {
   

    const legends = [
        { color: '#1F5987', label: 'Exemplary Stewardship' },
        { color: '#4D7FA9', label: 'Low Emissions' },
        { color: '#9793C2', label: 'Moderate Impact' },
        { color: '#FEB79D', label: 'Elevated Levels' },
        { color: '#C76E8A', label: 'High Emissions' },
      ];
  return (
    <div className=' fixed left-[380px] top-4 h-10 mr-4 mb-[200px] z-30'>
   <ExternalCard header={'Legend'}>
   <div className="  text-[12px] pb-5">
      {legends.map((legend, index) => (
        <div key={index} className="flex items-center mb-2">
          <div className={`w-3 h-3  rounded-sm mr-4`} style={{backgroundColor:`${legend.color}`}} />
          <span className="text-white ">{legend.label}</span>
        </div>
      ))}
    </div>
   </ExternalCard>
   </div>
  )
}

export default Indicators