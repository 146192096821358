import React, {useState, useRef} from 'react'

function PollutantDropdown(props) {
    const {selectedPollutant, setSelectedPollutant} = props
    const [isOpen, setIsOpen] = useState(false); // Dropdown visibility state
    // const [selectedPollutant, setSelectedPollutant] = useState('AQI'); // Currently selected option
    const dropdownRef = useRef(null); // Reference for detecting outside clicks
    const selectedPollutantRef = useRef(null); 

    const pollutants = [ 'AQI', 'SO2', 'CO', 'NO2', 'O3', 'PM10', 'PM2.5']

    const handlePollutantSelect = (pollutant) => {

        setSelectedPollutant(pollutant); // Set selected month
        setIsOpen(false);
    
        // if (selectedMonth == month) {
        //   return
        // }
        // if (month === 'This Month') {
        //   const currentMonth = format(new Date(), 'MM'); // Get current month as number (e.g., '09')
        //   const currentYear = format(new Date(), 'yyyy'); // Get current year (e.g., '2024')
        //   changeMonthYear(currentMonth, currentYear); // Call the parent function with current month/year
        // } else {
        //   const [monthName, year] = month.split(' '); // Split the selected month into month name and year
        //   const monthNumber = format(new Date(`${monthName} 01 ${year}`), 'MM'); // Convert month name to number (e.g., '09')
        //   changeMonthYear(monthNumber, year); // Call the parent function with selected month/year
        // }
      };

  return (
    <div className="relative inline-block text-left bg-black w-full" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)} // Toggle dropdown
          className="inline-flex bg-black    justify-center items-center font-[400]  text-white border  w-full rounded-sm   border-gray-300  px-[5px] py-[4px]  text-[12px]   hover:bg-gray-700 focus:outline-none   "
        >
          {selectedPollutant}
          <svg
            className={` -mr-[2px] -ml-[1px] h-4 w-4 transition-transform transform ${isOpen ? 'rotate-180' : ''
              }`} // Rotates icon when dropdown is open
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {/* Dropdown Items */}
        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-1  rounded-md shadow-lg bg-white backdrop-blur-sm  bg-opacity-30 ring-1 ring-black ring-opacity-5 z-10  overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400">
            <div className="py-1">
              {/* <a
                href="#"
                className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 ${selectedPollutant === 'This Month' ? 'bg-blue-100' : ''
                  }`}
                onClick={() => handleMonthSelect('This Month')}
                ref={selectedPollutant === 'This Month' ? selectedMonthRef : null} // Add ref to the selected item
              >
                This Month
              </a> */}
              {pollutants.map((pollutant, index) => (
                <a
                  key={index}
                  href="#"
                  className={` flex flex-col px-2 justify-center py-1 items-center text-sm text-gray-100 bg-white bg-transparent bg-opacity-5 hover:bg-gray-500 ${selectedPollutant === pollutant ? 'bg-blue-100' : ''
                    }`}
                  onClick={() => handlePollutantSelect(pollutant)}
                  ref={selectedPollutant === pollutant ? selectedPollutantRef : null} // Add ref to the selected item
                >
                  {pollutant}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
  )
}

export default PollutantDropdown