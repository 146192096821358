import React from 'react'
import DummySvg from './DummySvg'
import CardDragIcon from './CardDragIcon'

function ExternalCard(props) {
    const {helperText = false} = props;
  return (
    <div className='rounded-[30px] bg-[#142C3A] w-full '>
        <div className='border-b flex items-center gap-2 border-[#1A4C68] px-5 py-3 mb-4 text-sm text-white'>
            <CardDragIcon />
            <span className=' text-[14px]'>{props.header}</span>
            <span className=' text-xs pt-1 font-medium'>{props.area && Math.round(parseFloat(props.area))} 
           { props.area &&<span> km&sup2;</span>}
            </span>
            </div>
            {helperText?<div className=' text-[8px] text-white px-5 -mt-3'>One Tree = 25Kg of CO<sub>2</sub> less</div>:''}
        <div className=' flex px-6 flex-col overflow-auto'>
        
          {props.children}
        </div>
       
    </div>
  )
}

export default ExternalCard