import React, { useState, useEffect } from 'react';
import CarSvg from './CarSvg';
import TrukSvg from './TrukSvg';
import FactorySvg from './FactorySvg';
import LorrySvg from './LorrySvg';
import TreeReducedIcon from './TreeReducedIcon';
import CarbonDiOxideIcon from './CarbonDiOxideIcon';
import SubtractIcon from './SubtractIcon';
import AddIcon from './AddIcon';
import Loader from './Loader';

const TRACK_WIDTH_PX = 1.3;
const VIEW_BOX_SIZE_PX = 18;
const TRACK_SIZE_DEGREES = 240;

const SETTER_RADIUS_RATIO = 0.8;
const SETTER_STROKE_RATIO = 1 / 3;

function calculateSetterPosition(radius, size, value) {
    return {
        x: -radius * Math.sin(degToRad((value / 100) * size + (360 - size) / 2)),
        y: radius * Math.cos(degToRad((value / 100) * size + (360 - size) / 2)),
    };
}

function degToRad(deg) {
    return deg * (Math.PI / 180);
}

const DummySvg = ({ value = 0, maxVal = 100, showText = "lorry", des = "", main='', sub='', iconAction = false, handleAddTrees, handleSubractTrees, unit, loading }) => {
    const [animatedValue, setAnimatedValue] = useState(0);

    useEffect(() => {
        const animationDuration = 1000; // 1 second
        const steps = 60; // 60 fps
        const stepDuration = animationDuration / steps;
        const stepValue = (value - animatedValue) / steps;

        let currentStep = 0;

        const intervalId = setInterval(() => {
            if (currentStep < steps) {
                setAnimatedValue(prevValue => prevValue + stepValue);
                currentStep++;
            } else {
                setAnimatedValue(value);
                clearInterval(intervalId);
            }
        }, stepDuration);

        return () => clearInterval(intervalId);
    }, [value]);

    const percentageValue = Math.floor(animatedValue * 100 / maxVal);
    let parsedValue = parseFloat(animatedValue.toFixed(2));
    const valueOnScale = percentageValue > 100 ? 100 : percentageValue;

    const viewBox = `0 0 ${VIEW_BOX_SIZE_PX} ${VIEW_BOX_SIZE_PX}`;
    const radius = 7;

    const circumference = 2 * Math.PI * radius;
    const dasharray = circumference;
    const trackFillPercentage = TRACK_SIZE_DEGREES / 360;
    const trackDashoffset = circumference * (1 - trackFillPercentage);

    const setterRadius = TRACK_WIDTH_PX * SETTER_RADIUS_RATIO;
    const setterStrokeWidth = TRACK_WIDTH_PX * SETTER_STROKE_RATIO;
    const setterPos = calculateSetterPosition(radius, TRACK_SIZE_DEGREES, valueOnScale);
    const setterTranslate = `translate(${setterPos.x}, ${setterPos.y})`;

    const valuePercentage = (valueOnScale / 100) * trackFillPercentage;
    const valueDashoffset = circumference * (1 - valuePercentage);

    const cxy = VIEW_BOX_SIZE_PX * 0.5;
    const trackTransform = `rotate(${-(TRACK_SIZE_DEGREES / 2) - 90}, ${cxy}, ${cxy})`;

    return (
        <div className='relative w-full text-center flex items-center justify-center select-none'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} width={'200px'} className='transition-all duration-3000 ease-out'>
                <defs>
                    <linearGradient id="gradient" >
                        <stop stopColor="#C76E8A" />
                        <stop offset="0.0001" stopColor="#C76E8A" />
                        <stop offset="0.254996" stopColor="#FEB79D" />
                        <stop offset="0.505" stopColor="#9793C2" />
                        <stop offset="0.735" stopColor="#4D7FA9" />
                        <stop offset="1" stopColor="#1F5987" />
                    </linearGradient>
                </defs>

                <circle
                    fill="none"
                    cx="50%"
                    cy="50%"
                    r={radius}
                    stroke="white"
                    opacity={0.2}
                    strokeDasharray={dasharray}
                    strokeDashoffset={trackDashoffset}
                    strokeLinecap='round'
                    transform={trackTransform}
                    strokeWidth={TRACK_WIDTH_PX}
                />

                <circle
                    fill="none"
                    strokeLinecap="round"
                    cx="50%"
                    cy="50%"
                    r={radius}
                    stroke="url(#gradient)"
                    strokeDasharray={dasharray}
                    strokeDashoffset={valueDashoffset}
                    strokeWidth={TRACK_WIDTH_PX}
                    transform={trackTransform}
                />

                <circle
                    fill="#fff"
                    stroke="#fff"
                    cx="50%"
                    cy="50%"
                    r={setterRadius - 0.3}
                    strokeWidth={setterStrokeWidth}
                    transform={setterTranslate}
                />

                {(showText === "value" || showText === "PM" || showText === "NO2" || showText === "SO2" || showText === "O3" ||showText === "CO") && (
                    <text x="50%" y="50%" textAnchor="middle" fill="#fff" fontSize="5" className='text-[2.5px]'>
                       {loading?  
                       <circle
                       cx="50%"
                       cy="50%"
                       r="2.5"
                       stroke="#fff"
                       strokeWidth="1"
                       fill="none"
                       strokeDasharray="10"
                       strokeLinecap="round"
                       transform="rotate(-90 9 9)"
                       className="animate-spin"
                   />
                        : 
                        showText === "value"?
                        Math.floor(parsedValue):
                        showText === "CO"?
                        parsedValue:
                        parseFloat(parsedValue.toFixed(1))
                        }
                    </text>
                )}

                {showText === "car" && (
                    <svg x="40%" y="30%" textAnchor="middle" fill="#fff" fontSize="5">
                        <CarSvg />
                    </svg>
                )}

                {showText === "truck" && (
                    <svg x="38%" y="30%" textAnchor="middle" fill="#fff" fontSize="5">
                        <TrukSvg />
                    </svg>
                )}

                {showText === "factory" && (
                    <svg x="40%" y="30%" textAnchor="middle" fill="#fff" fontSize="5">
                        <FactorySvg />
                    </svg>
                )}

                {showText === "lorry" && (
                    <svg x="39%" y="30%" textAnchor="middle" fill="#fff" fontSize="5">
                        <LorrySvg />
                    </svg>
                )}

                {showText === "tree" && (
                    <svg x="39%" y="30%" textAnchor="middle" fill="#fff" fontSize="5">
                        <TreeReducedIcon />
                    </svg>
                )}

                {showText === "CO2Icon" && (
                    <svg x="37%" y="30%" textAnchor="middle" fill="#fff" fontSize="5">
                        <CarbonDiOxideIcon />
                    </svg>
                )}

                {(showText === "CO2" || showText === "NO2" || showText === "SO2" || showText === "O3" || showText === "PM") ? (
                    <text x="52%" y="65%" textAnchor="middle" fill="#fff" fontSize="1.7">
                        {main}<tspan dy="0.5" fontSize='1.2'>{sub}</tspan>
                    </text>
                ) : (
                    <text x="52%" y="65%" textAnchor="middle" fill="#fff" fontSize="1.4" className='select-none'>
                        {des}
                    </text>
                )}

                {unit && (
                    <text x="52%" y="75%" textAnchor="middle" fill="#fff" fontSize="1" className='select-none'>
                        ({unit})
                    </text>
                )}

                {iconAction ? (
                    <svg x="10%" y="80%">
                        <svg x="5%" className='cursor-pointer' onClick={handleSubractTrees}>
                            <SubtractIcon />
                        </svg>
                        <svg x="65%" className='cursor-pointer' onClick={handleAddTrees}>
                            <AddIcon />
                        </svg>
                    </svg>
                ) : (
                    <>
                        <text x="20%" y="80%" textAnchor="middle" fill="#fff" fontSize="1.5" dy="1em">
                            Low
                        </text>
                        <text x="80%" y="80%" textAnchor="middle" fill="#fff" fontSize="1.5" dy="1em">
                            High
                        </text>
                    </>
                )}
            </svg>
        </div>
    );
}

export default DummySvg;