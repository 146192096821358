import React, { useState, useRef, useEffect } from 'react'
import ButtomPanel from './ButtomPanel'
import MapControl from './Map-controls/MapControl'
import MapContainer from './MapContainer'
import * as turf from '@turf/turf';
import { getPollutantsFromPolygon, getPollutantsWithinPolygon, getVehicalsFromPolygon, getVehicalsWithinPolygon } from '../Actions/userAction';
import Indicators from './Map-controls/Indicators';

function MainLayout(props) {
  const { marker, setMarker, mapref, selectedPollutant, setSelectedPollutant,  area, time, setTime, vehicalPolygonAverage, initialPollutionData, aggregateVehicleData, setStartDrawMode, startDrawMode, polygonMode, setPolygonMode, currentTime, handleDrawDelete, drawRef, map, setMap, draw, setDraw } = props



  return (
    <div className='h-full relative'>
      <div>
        <MapControl setStartDrawMode={setStartDrawMode} selectedPollutant={selectedPollutant}  setSelectedPollutant={setSelectedPollutant} startDrawMode={startDrawMode} polygonMode={polygonMode} setPolygonMode={setPolygonMode} handleDrawDelete={handleDrawDelete} drawRef={drawRef}
        // onClick={handleMapControl} 
        />
      </div>
      <div>
        <Indicators />
      </div>
      <MapContainer
        time={time}
        mapref={mapref}
        setTime={setTime}
        marker={marker}
        setMarker={setMarker}
        selectedPollutant={selectedPollutant}
        polygonMode={polygonMode}
        setPolygonMode={setPolygonMode}
        startDrawMode={startDrawMode}
        setStartDrawMode={setStartDrawMode}
        drawRef={drawRef}
        map={map}
        setMap={setMap}
        draw={draw}
        setDraw={setDraw} />
      {/* <div className=' ml-[365px]'>
        <ButtomPanel
          aggregateVehicleData={aggregateVehicleData}
          initialPollutionData={initialPollutionData}
          vehicalPolygonAverage={vehicalPolygonAverage}
          area={area}
          polygonMode={polygonMode}
          time={time}
          setTime={setTime}
          currentTime={currentTime} /></div> */}
    </div>
  )
}

export default MainLayout