import React from 'react'

function Loader() {
  return (
    <text class="flex items-center justify-center w-full">
    <text class="w-16 h-16 border-4 border-t-transparent border-white rounded-full animate-spin"></text>
  </text>
  )
}

export default Loader