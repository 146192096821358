import React from 'react';
// import SplitMapButton from './split-map-button';
// import Toggle3dButton from './toggle-3d-button';
// import LayerSelectorPanel from './layer-selector-panel';
// import MapLegendPanel from './map-legend-panel';
import MapDrawPanel from './map-draw-panel';
import DrawIcon from '../../utils/DrawIcon';
// import LocalePanel from './locale-panel';
import IconButton from '../../utils/IconButton';
import SelectModeIcon from '../../utils/SelectModeIcon';
import TrashIcon from '../../utils/TrashIcon';
import CropIcon from '../../utils/CropIcon';
import PollutantDropdown from '../PollutantDropdown';

const DEFAULT_ACTIONS = [MapDrawPanel];

const MapControl = (props) => {
  const {drawRef, handleDrawDelete, selectedPollutant, setSelectedPollutant,  polygonMode, setPolygonMode, startDrawMode, setStartDrawMode} = props;

  

  const toggleDrawPolygon = () => {
    
    if (drawRef.current) {
      if(polygonMode){
        setPolygonMode(false)
          drawRef.current.deleteAll();
      }
      const currentMode = drawRef.current.getMode();
      const newMode = currentMode === 'draw_polygon' ? 'simple_select' : 'draw_polygon';
      drawRef.current.changeMode(newMode);
      if(newMode === 'draw_polygon'){
        setStartDrawMode(true);
      }
        
    }
  };
  
  const deleteAll = () => {
    handleDrawDelete()
  };
  
  const selectMode = () => {
    if (drawRef.current) {
      drawRef.current.changeMode('simple_select');
    }
  };





  return (
    <div className="map-control flex flex-col justify-center items-center mr-5 gap-2"  style={{ position: 'fixed', right: 0, marginTop: 25, zIndex: 10 }}>
        <div className=' cursor-pointer' onClick={toggleDrawPolygon}>
        <IconButton startDrawMode={startDrawMode}>
        <CropIcon startDrawMode={startDrawMode}  />
        </IconButton> 
        </div>
        <div className=' cursor-pointer h-11 w-11' onClick={deleteAll} >
        <IconButton >
           <TrashIcon />
        </IconButton> 
        </div>

        <div className=' cursor-pointer w-14'>
          <PollutantDropdown setSelectedPollutant={setSelectedPollutant} selectedPollutant={selectedPollutant}  />
        </div>


        <div className=' cursor-pointer' onClick={selectMode}>
        {/* <IconButton>
        <DrawIcon />
        </IconButton>  */}
        </div>
      {/* {actionComponents.map((ActionComponent, index) => {
      
          return   <ActionComponent key={index} buttonClick={onClick}  />
        // return <div>gii</div>
      }
      )} */}
    </div>
  );
};

export default MapControl;