import React, { useState } from 'react'
// import { FlyToInterpolator } from 'react-map-gl';
import {FlyToInterpolator} from '@deck.gl/core';

const TOKEN = "pk.eyJ1Ijoic2luZ2h2aXNoYW4wMDI5IiwiYSI6ImNsdTVwNDdtajE0OGgya254bW1qcGxuc2oifQ.O5Mw_RmNEyFN6N4eZucRWw"; // Set your mapbox token here


function CustomGeocoder(props) {
    const [input, setInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = async (event) => {
        setInput(event.target.value);

        const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=${TOKEN}`);
        const data = await response.json();
        
       
        setSuggestions(data.features);
    };

    const handleClose = () => {
        props.setMarker(null);
        setInput('');
        setSuggestions([]);
    }

    const handleSuggestionClick = async (suggestion) => {
        const [longitude, latitude] = suggestion.center;
      
      
        props.mapref.current?.flyTo({center: [longitude, latitude], duration: 2000});
        // props.onViewPortChange({
        //     latitude,
        //     longitude,
        //     zoom: 14,
        //     transitionInterpolator: new FlyToInterpolator({speed: 2}),
        //     transitionDuration: 'auto'
        // });
        // const response2  =  await fetch(`https://api.mapbox.com/v4/mapbox.your-tileset/${longitude},${latitude}.json?access_token=${TOKEN}`);
        // const data2 = await response2.json();
       
        setInput(suggestion.place_name);
        props.setMarker({longitude, latitude});
        setSuggestions([]);
    }



    return (
        <div className="  w-full">
            <div className="  w-full">
                <div className="max-w-md mx-auto rounded-lg overflow-hidden md:max-w-xl">
                    <div className="md:flex">
                        <div className="w-full ">

                            <div className="relative">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="w-6 h-6 absolute mx-4 my-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>

                                <input
                                    value={input}
                                    onChange={handleInputChange}
                                    type="text"
                                    className="bg-[#224255] text-white h-14 w-full pl-12 pr-16 rounded-lg focus:outline-none hover:cursor-pointer"
                                    name=""
                                    placeholder=' Go to Loction' />
                                {input && <span className="absolute top-4 right-5  pl-2">
                                    {/* <i className="fa fa-microphone text-gray-500 hover:text-green-500 hover:cursor-pointer"></i> */}
                                    <span onClick={handleClose} className=' cursor-pointer'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                    </span>

                                </span>}
                            </div>
                            <div className=" absolute mt-1 w-full max-w-[318px] z-10 rounded-b-lg bg-white shadow-md overflow-hidden">
                                {suggestions.map(suggestion => (
                                    <div
                                        key={suggestion.id} 
                                        onClick={() => handleSuggestionClick(suggestion)}
                                        className="px-4 py-2  cursor-pointer hover:bg-gray-200"
                                    >
                                        {suggestion.place_name}
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default CustomGeocoder