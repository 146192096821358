import React, { useEffect, useReducer, useRef, useState } from 'react'
import CircularProgress from '../utils/CircularBar'
import Card from '../utils/Card'
//import Geocoder from 'react-map-gl-geocoder';
// import GeocoderControl from './Geocoder'
// import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import CustomGeocoder from './CustomGeocoder';
import DummySvg from '../utils/DummySvg';
import CautionIcon from '../utils/CautionIcon';
import HelperText from '../utils/HelperText';
import YardsSvg from '../utils/YardsSvg';
import TreeIcon from '../utils/TreeIcon';
import { getPollutants, getReverseGeocode, getTopPollutantCity, getVehicalData } from '../Actions/userAction';
// import './geocoder.css'
// import CustomGeocoder from './Geocoder';


const TOKEN = "pk.eyJ1Ijoic2luZ2h2aXNoYW4wMDI5IiwiYSI6ImNsdTVwNDdtajE0OGgya254bW1qcGxuc2oifQ.O5Mw_RmNEyFN6N4eZucRWw"; // Set your mapbox token here


function SidePanel(props) {
    const { selectedPollutant, time, marker, setMarker, aggregateVehicleData, pollutionData, loading} = props
    const [des, setDes] = useState(`${aggregateVehicleData} Cars`)

    // const [loading, setLoading] = useState(false)
    const [mostEffectedAreas, setMostEffectedAreas] = useState([])
    const area_ref = useRef(null)
    //const [pollutionData, setPollutionData] = useState({})
   
    useEffect(()=>{
        setDes(`${aggregateVehicleData} Cars`)
    },[aggregateVehicleData])

    // const pollutants = {
    //     'CO2': 126.543,
    //     'PM2.5': 345,
    //     'HC': 230,
    //     "CO": 450,
    //     'NOx': 320,
    //     'PM10': 390

    // }

    

   

    
    useEffect(() => {
       getCity();
    }, [time, selectedPollutant])

    const getCity = async() =>{
        const response = await getTopPollutantCity(time, selectedPollutant);
        const cities = []
        console.log("response for city", response)
        // setMostEffectedAreas(response)
        response?.map( async(item, index)=>{
        const p1 = await getReverseGeocode(item?.coordinates[1],item?.coordinates[0])
        cities.push( {name : p1?.features[0]?.properties?.name_preferred})
        setMostEffectedAreas(cities)
        })

        
    }

    // const mostEffectedAreas = [
    //     {
    //         name: 'Union Square',
    //         pollution: 300
    //     },
    //     {
    //         name: 'Nob Hill',
    //         pollution: 200
    //     },
    //     {
    //         name: 'Japantown',
    //         pollution: 100
    //     }
    // ]

    


    return (
        <div
            className=' w-full bg-[#142C3A] h-full  top-0 left-0 z-50 shadow-lg flex  flex-col '
        >
            <div className='flex flex-col  mt-15  w-full p-4 gap-7 h-full overflow-y-auto '>
                <div className=' flex justify-center'>
                    <CustomGeocoder mapref={props.mapref} setMarker={setMarker} onViewPortChange={props.onViewPortChange} />
                </div>
                <Card>
                    <div className=' justify-center flex flex-col items-center py-4'>
                        <DummySvg loading={loading} value={pollutionData['AQI']<=0?0:pollutionData['AQI']} maxVal={500} showText="value" des={'AQI'}  />
                        <div className=' font-sans text-white'>Moderate Air Quality</div>
                    </div>
                </Card>

                <Card>
                    <div className=' py-5'>
                        <div className=' flex flex-row justify-evenly'>
                            <div className=' w-[138px] h-38 '>
                                <DummySvg loading={loading} value={pollutionData['NO2']<=0?0:pollutionData['NO2']} maxVal={150} showText='NO2'  main='NO' sub='2'  des={'NO2'} unit={'µg/m3'}  />
                                {/* <HelperText /> */}
                            </div>
                            <div className=' w-[138px] h-38 '>
                                {/* <DummySvg showText='truck' des="NA" /> */}
                                <DummySvg loading={loading} value={pollutionData['CO']<=0?0:pollutionData['CO']} maxVal={1} showText='CO' des={'CO'} unit={'µg/m3'} />
                                {/* <HelperText /> */}
                            </div>
                        </div>
                        <div>
                            <div className=' flex flex-row justify-evenly'>
                                <div className=' w-[138px] h-38 '>
                                    {/* <DummySvg showText='lorry' des="NA" /> */}
                                    <DummySvg loading={loading} value={pollutionData['SO2']<=0?0:pollutionData['SO2']} maxVal={150} showText='SO2' main='SO' sub='2' des={'SO2'} unit={'µg/m3'}  />
                                    {/* <HelperText /> */}
                                </div>
                                <div className=' w-[138px] h-38 '>
                                    {/* <DummySvg showText='factory' des="NA" /> */}
                                    <DummySvg loading={loading} value={pollutionData['O3']<=0?0:pollutionData['O3']} maxVal={120} showText='O3' main='O' sub='3' des={'O3'} unit={'µg/m3'}  />
                                    {/* <HelperText /> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className=' flex flex-row justify-evenly'>
                                <div className=' w-[138px] h-38 '>
                                    {/* <DummySvg showText='lorry' des="NA" /> */}
                                    <DummySvg loading={loading} value={pollutionData['PM10']<=0?0:pollutionData['PM10']} maxVal={500} showText='PM' main='PM' sub='10' des={'PM10'} unit={'µg/m3'} />
                                    {/* <HelperText /> */}
                                </div>
                                <div className=' w-[138px] h-38 '>
                                    {/* <DummySvg showText='factory' des="NA" /> */}
                                    <DummySvg loading={loading} value={pollutionData['PM2.5']<=0?0:pollutionData['PM2.5']} maxVal={500} showText='PM' main='PM' sub='2.5' des={'PM2.5'} unit={'µg/m3'} />
                                    {/* <HelperText /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card>
                    <div className='px-10 py-1'>
                        <div className=' text-white text-[12px] py-3'>What Am I Breathing Right Now</div>
                        {
                            Object.keys(pollutionData).map((pollutant, index) => (
                                <div key={index} className='flex  items-center first:py-0 last:pb-9  py-3 gap-3 last:border-0 border-b border-[#1A4C68]'>

                                    <div className=' text-white text-xs flex justify-between w-full'>
                                        <div>  {pollutant} </div>
                                        <div> {parseFloat(pollutionData[pollutant].toFixed(2))} </div>
                                    </div>

                                </div>
                            ))
                        }

                    </div>
                </Card>

                <Card>
                    <div className=' px-10 py-1'>
                        <div className=' text-white  py-3'>Most Affected Areas</div>
                        {
                            mostEffectedAreas?.map((area, index) => (
                                <div key={index} className='flex  items-center first:py-0 last:pb-9  py-3 gap-3 last:border-0 border-b border-[#1A4C68]'>
                                    {console.log("area from map", area.name)}
                                    <CautionIcon />
                                    <div className=' text-white text-xs'>{area.name}</div>

                                </div>
                            ))
                        }
                    </div>
                </Card>

                {/* <Card>
                    <div className=' flex flex-col justify-center items-center'>
                        <DummySvg value={33} maxVal={100} showText='value' des="Average" />
                        <div className=' w-44 h-12'> <HelperText CO2='3 tons' NOX='3 tons' large={true} /></div>
                    </div>
                </Card> */}


                <Card>
                    <div className=' flex  justify-between px-10 gap-2 items-end py-3'>
                        <div className='  mb-5'>
                            <YardsSvg text={"NA Yards"} />
                        </div>
                        <div className='  mb-5'>
                            <TreeIcon value={"NA Counts"} />
                        </div>
                    </div>
                </Card>


            </div>
        </div>
    )
}

export default SidePanel